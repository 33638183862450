import * as React from 'react';
import { graphql } from 'gatsby';

// components
import Seo from '@src/components/SEO';

// utils
import Hero from '@src/components/Hero';

export const query = graphql`
    {
        page: craftHomeHomeEntry {
            id: remoteId
            title
            typeHandle
            seo {
                title
                description
                keywords {
                    keyword
                }
                advanced {
                    robots
                    canonical
                }
                social {
                    twitter {
                        image {
                            ... on Craft_images_Asset {
                                id
                                url
                            }
                        }
                    }
                    facebook {
                        image {
                            ... on Craft_images_Asset {
                                id
                                url
                            }
                        }
                    }
                }
            }
            hero {
                ... on Craft_hero_contentSlide_BlockType {
                    id
                    slideAsset {
                        ... on Craft_images_Asset {
                            id
                            title
                            url
                            imageFile {
                                childImageSharp {
                                    gatsbyImageData
                                }
                            }
                        }
                    }
                    slideCopy
                    slideLink
                }
            }
        }
    }
`;

const IndexPage = ({ data: { page }, location }) => {
    const { seo } = page;
    const keywords = [];
    if (seo.keywords) {
        seo.keywords.forEach(keyword => {
            keywords.push(keyword.keyword);
        });
    }
    const { hero } = page;

    return (
        <>
            <Seo
                title={seo.title}
                canonical={seo.advanced.canonical}
                description={seo.description}
                facebookImage={seo.social.facebook.image?.url}
                keywords={keywords}
                robots={seo.advanced.robots}
                twitterImage={seo.social.twitter.image?.url}
                modifiedTime={page.dateUpdated}
                url={location.href}
                landing
            />
            <Hero data={hero} />
        </>
    );
};

export default IndexPage;
