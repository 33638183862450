import React from 'react';
import Asset from '@src/components/Asset';
import Carousel from '@src/components/glide/Glide';

export default function Hero(props) {
    const { data } = props;

    const carouselOptions = {
        type: 'carousel',
        bound: true,
        perView: 1,
        autoplay: 3000,
        animationDuration: 1000,
        startAt: 0,
        peek: 0,
        gap: 0,
    };

    return (
        <div className="hero">
            <Carousel full enabled options={carouselOptions} bullets={data.length > 1}>
                {data.map(item => (
                    <div className="hero-item" key={item.slideAsset[0].title}>
                        {item.slideAsset[0].url.indexOf('mov') === -1 &&
                        item.slideAsset[0].url.indexOf('mp4') === -1 ? (
                            <>
                                {item.slideAsset[0].assetLink ? (
                                    <a href={item.slideAsset[0].assetLink}>
                                        <Asset
                                            data={item.slideAsset[0].imageFile}
                                            title={item.slideAsset[0].title}
                                        />
                                    </a>
                                ) : (
                                    <Asset
                                        data={item.slideAsset[0].imageFile}
                                        title={item.slideAsset[0].title}
                                    />
                                )}
                            </>
                        ) : (
                            <>
                                <video
                                    src={item.slideAsset[0].url}
                                    style={{
                                        position: 'absolute',
                                        width: 'auto',
                                        height: 'auto',
                                        minWidth: '100vw',
                                        minHeight: '100vh',
                                        top: 0,
                                        left: 0,
                                    }}
                                    muted
                                    autoPlay
                                />
                            </>
                        )}

                        {item.slideCopy && item.slideLink ? (
                            <a className="hero-item__button" href={item.slideLink}>
                                <button type="button" className="button">
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: item.slideCopy,
                                        }}
                                    />
                                </button>
                            </a>
                        ) : null}
                    </div>
                ))}
            </Carousel>
        </div>
    );
}
